import { Typography, withStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation } from 'react-i18next';
import i18n from "../../i18n";
import { compose, pure, setDisplayName } from "recompose";
import { Dialog, Form, FormButton, FormInput, Header, Notification, TextLink } from "components";
import { withForm } from "enhancers";
import { styles } from "./Reset.styles";
export var Render = function (_a) {
    var classes = _a.classes, form = _a.form, request = _a.request, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, t = _a.t;
    return React.createElement(Dialog, null,
        React.createElement(Header, { primary: window.env.COGNITO_IDENTITY_POOL_NAME, secondary: t("unlock_account") }),
        React.createElement(Notification, null),
        React.createElement(Form, { onSubmit: handleSubmit },
            React.createElement(Typography, null,
                t("reset_invite"),
                t("send_link")),
            React.createElement(FormInput, { name: "username", label: t("email_address"), required: true, disabled: form.success, value: request.username, InputProps: { readOnly: form.pending }, onChange: handleChange, autoComplete: "email" }),
            React.createElement(FormButton, { className: classes.button, disabled: form.pending || form.success }, t("reset_password")),
            React.createElement(Typography, { className: classes.authenticate },
                t("password_remembered"),
                " ",
                React.createElement(TextLink, { to: "/" }, t("sign_in")))));
};
export function enhance() {
    return compose(withTranslation(), withStyles(styles), withForm({
        message: i18n.t("sent_link") +
            i18n.t("reset_click"),
        initial: {
            username: ""
        }
    }), pure, setDisplayName("Reset"));
}
export var Reset = enhance()(Render);
