var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { withStyles } from "@material-ui/core";
import { omit } from "ramda";
import * as React from "react";
import { Link } from "react-router-dom";
import { compose, mapProps, pure, setDisplayName, withHandlers } from "recompose";
import { withNotification } from "enhancers";
import { styles } from "./TextLink.styles";
export var Render = function (_a) {
    var classes = _a.classes, children = _a.children, handleClickCapture = _a.handleClickCapture, props = __rest(_a, ["classes", "children", "handleClickCapture"]);
    return React.createElement(Link, __assign({ className: classes.root, onClickCapture: handleClickCapture }, props), children);
};
export function enhance() {
    return compose(withStyles(styles), withNotification(), withHandlers({
        handleClickCapture: function (_a) {
            var dismissNotification = _a.dismissNotification;
            return dismissNotification;
        }
    }), mapProps(omit([
        "notification",
        "displayNotification",
        "dismissNotification"
    ])), pure, setDisplayName("TextLink"));
}
export var TextLink = enhance()(Render);
