import { colors, createStyles } from "@material-ui/core";
export var styles = function (_a) {
    var palette = _a.palette, spacing = _a.spacing;
    return createStyles({
        root: {
            background: colors.blueGrey[900],
            padding: spacing(4)
        },
        text: {
            color: palette.common.white,
            fontSmoothing: "antialiased"
        }
    });
};
