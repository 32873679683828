import { parse } from "query-string";
import { keysIn } from "ramda";
import { compose, withHandlers } from "recompose";
export var withCallback = function () {
    return compose(withHandlers({
        callback: function () { return function (id) {
            var redirect = parse(location.search).redirect;
            var path = (redirect || "").replace(/^\//, "");
            var hash = keysIn(id)
                .map(function (key) { return key + "=" + id[key]; })
                .join("&");
            location.assign("//" + window.env.APP_ORIGIN + "/" + path + "#" + hash);
        }; }
    }));
};
