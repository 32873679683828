import { createStyles } from "@material-ui/core";
export var styles = function (_a) {
    var palette = _a.palette, spacing = _a.spacing;
    return createStyles({
        controls: {
            display: "flex",
            alignItems: "center"
        },
        forgotPassword: {
            flex: 1,
            textAlign: "right"
        },
        register: {
            color: palette.text.hint,
            marginTop: spacing(2),
            textAlign: "center"
        }
    });
};
