import { colors, createStyles } from "@material-ui/core";
export var styles = function (_a) {
    var palette = _a.palette, spacing = _a.spacing;
    return createStyles({
        root: {
            background: '#fff',
            border: '2px solid ' + colors.grey[300],
            borderRadius: '4px',
            textTransform: 'none',
            margin: '15px 0',
            textAlign: 'center'
        },
        text: {
            color: palette.common.white,
            fontSmoothing: "antialiased"
        }
    });
};
