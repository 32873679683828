import { createStyles } from "@material-ui/core";
export var styles = function (_a) {
    var _b;
    var palette = _a.palette, breakpoints = _a.breakpoints;
    return createStyles({
        root: (_b = {
                display: "flex",
                height: "100%",
                minHeight: 600,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: palette.background.default
            },
            _b[breakpoints.down("xs")] = {
                height: "auto",
                alignItems: "initial",
                minHeight: "100%",
                backgroundColor: palette.common.white
            },
            _b)
    });
};
