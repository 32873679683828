import { SessionActionTypes } from "./actions";
export function session(state, action) {
    if (state === void 0) { state = null; }
    switch (action.type) {
        case SessionActionTypes.INIT:
            var _a = action.session, id = _a.id, access = _a.access;
            return { id: id, access: access, renewed: true };
        case SessionActionTypes.TERMINATE:
            return null;
        default:
            return state;
    }
}
