import { branch, compose, pure, renderComponent, setDisplayName } from "recompose";
import { withRememberMe } from "enhancers";
import { AuthenticateWithCredentials } from "./AuthenticateWithCredentials";
import { AuthenticateWithToken } from "./AuthenticateWithToken";
export var Render = AuthenticateWithCredentials;
export function enhance() {
    return compose(withRememberMe(), branch(function (_a) {
        var _b = _a.remember, active = _b.active, failed = _b.failed;
        return active && !failed;
    }, renderComponent(AuthenticateWithToken)), pure, setDisplayName("Authenticate"));
}
export var Authenticate = enhance()(Render);
