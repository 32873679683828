import { createStyles } from "@material-ui/core";
export var styles = function (_a) {
    var spacing = _a.spacing;
    return createStyles({
        root: {
            borderRadius: 2,
            fontSmoothing: "antialiased",
            marginTop: spacing(2)
        }
    });
};
