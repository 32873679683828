import { colors, createStyles } from "@material-ui/core";
export var styles = function (_a) {
    var palette = _a.palette, spacing = _a.spacing;
    return createStyles({
        root: {
            color: palette.common.white,
            fontSmoothing: "antialiased",
            fontWeight: 700,
            paddingTop: spacing(2),
            paddingRight: spacing(4),
            paddingBottom: spacing(2),
            paddingLeft: spacing(4),
            transition: "background-color .25s"
        },
        success: {
            backgroundColor: colors.green.A700
        },
        error: {
            backgroundColor: palette.error.main
        }
    });
};
