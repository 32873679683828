var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NotificationActionTypes } from "./actions";
export var NotificationType;
(function (NotificationType) {
    NotificationType["SUCCESS"] = "SUCCESS";
    NotificationType["ERROR"] = "ERROR";
})(NotificationType || (NotificationType = {}));
export function notification(state, action) {
    if (state === void 0) { state = { show: false }; }
    switch (action.type) {
        case NotificationActionTypes.DISPLAY:
            return { data: action.data, show: true };
        case NotificationActionTypes.DISMISS:
            return __assign(__assign({}, state), { show: false });
        default:
            return state;
    }
}
