import { createStyles } from "@material-ui/core";
export var styles = function (_a) {
    var palette = _a.palette, spacing = _a.spacing;
    return createStyles({
        authenticate: {
            color: palette.text.hint,
            marginTop: spacing(2),
            textAlign: "center"
        },
        button: {
            borderRadius: 2,
            fontSmoothing: "antialiased",
            marginTop: spacing(4)
        }
    });
};
