import { initReactI18next } from "react-i18next";
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import common_fr from "translations/fr/common.json";
import common_en from "translations/en/common.json";
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    interpolation: { escapeValue: false },
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    load: 'languageOnly',
    resources: {
        en: {
            translation: common_en
        },
        fr: {
            translation: common_fr
        },
    }
});
export default i18n;
