import * as React from "react";
import { compose, lifecycle, pure, setDisplayName } from "recompose";
import { withCallback, withSession } from "enhancers";
export var Render = function () { return React.createElement("div", null); };
export function enhance() {
    return compose(withSession(), withCallback(), lifecycle({
        componentDidMount: function () {
            var _a = this.props, form = _a.form, initSession = _a.initSession;
            if (form.response)
                initSession(form.response);
        },
        componentDidUpdate: function () {
            var _a = this.props, session = _a.session, callback = _a.callback;
            if (session && session.renewed)
                callback(session.id);
        }
    }), pure, setDisplayName("AuthenticateWithProviderSuccess"));
}
export var AuthenticateWithProviderSuccess = enhance()(Render);
