import { Typography, withStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation } from 'react-i18next';
import i18n from "../../i18n";
import { branch, compose, pure, renderComponent, setDisplayName } from "recompose";
import { Dialog, Form, FormButton, FormPassword, Header, Notification, TextLink } from "components";
import { withForm } from "enhancers";
import { styles } from "./ResetVerification.styles";
import { ResetVerificationSuccess } from "./ResetVerificationSuccess";
export var Render = function (_a) {
    var classes = _a.classes, form = _a.form, request = _a.request, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, t = _a.t;
    return React.createElement(Dialog, null,
        React.createElement(Header, { primary: window.env.COGNITO_IDENTITY_POOL_NAME, secondary: t("unlock_account") }),
        React.createElement(Notification, null),
        React.createElement(Form, { onSubmit: handleSubmit },
            React.createElement(FormPassword, { name: "password", label: t("new_password"), required: true, disabled: form.success, value: request.password, InputProps: { readOnly: form.pending }, onChange: handleChange, autoComplete: "new-password" }),
            React.createElement(FormButton, { className: classes.button, disabled: form.pending || form.success }, t("reset_password")),
            React.createElement(Typography, { className: classes.authenticate },
                t("password_remembered"),
                " ",
                React.createElement(TextLink, { to: "/" }, t("sign_in")))));
};
export function enhance() {
    return compose(withTranslation(), withStyles(styles), withForm({
        message: i18n.t("reset_success") +
            i18n.t("email_and_pass"),
        initial: {
            password: ""
        }
    }), branch(function (_a) {
        var form = _a.form;
        return form.success;
    }, renderComponent(ResetVerificationSuccess)), pure, setDisplayName("ResetVerification"));
}
export var ResetVerification = enhance()(Render);
