import { withStyles } from "@material-ui/core";
import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { styles } from "./samlIcon.styles";
export var Render = function (_a) {
    var classes = _a.classes;
    return React.createElement("div", { className: classes.root },
        React.createElement("svg", { viewBox: "0 0 32 32", xmlns: "http://www.w3.org/2000/svg", width: "32", height: "32" },
            React.createElement("g", { "data-name": "Shield Lock", id: "Shield_Lock-2" },
                React.createElement("path", { fill: "#d8e1ef", d: "M28,6C19.51,6,16.84,2.46,16.82,2.43A1,1,0,0,0,16,2a.94.94,0,0,0-.83.42S12.49,6,4,6A1,1,0,0,0,3,7v8.76c0,9.25,7.11,12,11.35,13.66l1.27.5a.94.94,0,0,0,.76,0l1.26-.5C21.89,27.77,29,25,29,15.76V7A1,1,0,0,0,28,6Z" }),
                React.createElement("path", { fill: "#0e6ae0", d: "M18,15H14a1,1,0,0,1-1-1V12a3,3,0,0,1,6,0v2A1,1,0,0,1,18,15Zm-3-2h2V12a1,1,0,0,0-2,0Z" }),
                React.createElement("rect", { fill: "#0593ff", height: "10", rx: "3", ry: "3", width: "10", x: "11", y: "13" }),
                React.createElement("path", { fill: "#0e6ae0", d: "M16,20a2,2,0,1,1,2-2A2,2,0,0,1,16,20Zm0-2Z" }))));
};
export function enhance() {
    return compose(withStyles(styles), setDisplayName("SamlIcon"));
}
export var SamlIcon = enhance()(Render);
