import { Typography, withStyles } from "@material-ui/core";
import * as React from "react";
import { compose, pure, setDisplayName } from "recompose";
import { styles } from "./Header.styles";
export var Render = function (_a) {
    var classes = _a.classes, primary = _a.primary, secondary = _a.secondary;
    return React.createElement("div", { className: classes.root + ' auth-header' },
        React.createElement(Typography, { variant: "h5", className: classes.text }, primary),
        React.createElement(Typography, { className: classes.text }, secondary));
};
export function enhance() {
    return compose(withStyles(styles), pure, setDisplayName("Header"));
}
export var Header = enhance()(Render);
