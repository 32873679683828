import { connect } from "react-redux";
import { setRememberMeAction, setRememberMeFailedAction } from "providers/store/remember-me";
export var withRememberMe = function () {
    return connect(function (_a) {
        var remember = _a.remember;
        return ({ remember: remember });
    }, function (dispatch) { return ({
        setRememberMe: function (active) { return dispatch(setRememberMeAction(active)); },
        setRememberMeFailed: function () { return dispatch(setRememberMeFailedAction()); }
    }); });
};
