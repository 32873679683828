import { withStyles } from "@material-ui/core";
import * as React from "react";
import { compose, setDisplayName } from "recompose";
import { styles } from "./googleIcon.styles";
export var Render = function (_a) {
    var classes = _a.classes;
    return React.createElement("div", { className: classes.root },
        React.createElement("svg", { width: "27", height: "27", viewBox: "0 0 27 27", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M25.8477 13.4873C25.8477 12.5522 25.7638 11.6531 25.6079 10.7899H13.188V15.891H20.2851C19.9794 17.5393 19.0503 18.936 17.6536 19.8711V23.1799H21.9155C24.4091 20.8841 25.8477 17.5034 25.8477 13.4873Z", fill: "#4285F4" }),
            React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M13.1874 26.3742C16.7479 26.3742 19.733 25.1934 21.9149 23.1793L17.653 19.8706C16.4722 20.6618 14.9616 21.1293 13.1874 21.1293C9.75271 21.1293 6.84553 18.8096 5.80854 15.6926H1.40283V19.1093C3.57272 23.4191 8.03238 26.3742 13.1874 26.3742Z", fill: "#34A853" }),
            React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M5.80835 15.6927C5.5446 14.9015 5.39475 14.0563 5.39475 13.1871C5.39475 12.318 5.5446 11.4728 5.80835 10.6816V7.26489H1.40263C0.509504 9.04516 0 11.0592 0 13.1871C0 15.3151 0.509504 17.3291 1.40263 19.1094L5.80835 15.6927Z", fill: "#FBBC05" }),
            React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M13.1874 5.2449C15.1235 5.2449 16.8618 5.91025 18.2285 7.21698L22.0108 3.43466C19.727 1.30673 16.7419 0 13.1874 0C8.03238 0 3.57272 2.95512 1.40283 7.26493L5.80854 10.6816C6.84554 7.56464 9.75271 5.2449 13.1874 5.2449Z", fill: "#EA4335" })));
};
export function enhance() {
    return compose(withStyles(styles), setDisplayName("GoogleIcon"));
}
export var GoogleIcon = enhance()(Render);
