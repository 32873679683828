import { FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel, withStyles } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as React from "react";
import { compose, pure, setDisplayName, withHandlers, withState } from "recompose";
import { styles } from "./FormPassword.styles";
export var Render = function (_a) {
    var classes = _a.classes, name = _a.name, label = _a.label, required = _a.required, InputProps = _a.InputProps, value = _a.value, onChange = _a.onChange, autoComplete = _a.autoComplete, disabled = _a.disabled, error = _a.error, helperText = _a.helperText, visibility = _a.visibility, handleClick = _a.handleClick, handleMouseDown = _a.handleMouseDown;
    return React.createElement(FormControl, { margin: "dense", fullWidth: true, error: error },
        React.createElement(InputLabel, { htmlFor: name, required: false }, label),
        React.createElement(Input, { id: name, name: name, type: visibility ? "text" : "password", required: required, readOnly: InputProps ? InputProps.readOnly : false, value: value, onChange: onChange, autoComplete: autoComplete, disabled: disabled, endAdornment: React.createElement(InputAdornment, { position: "end", className: classes.adornment },
                React.createElement(IconButton, { "aria-label": "Toggle password visibility", onClick: handleClick, onMouseDown: handleMouseDown, tabIndex: -1 }, visibility ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null))) }),
        helperText && React.createElement(FormHelperText, null, helperText));
};
export function enhance() {
    return compose(withStyles(styles), withState("visibility", "setVisibility", function () { return false; }), withHandlers({
        handleClick: function (_a) {
            var visibility = _a.visibility, setVisibility = _a.setVisibility;
            return function () {
                setVisibility(!visibility);
            };
        },
        handleMouseDown: function () { return function (ev) {
            ev.preventDefault();
        }; }
    }), pure, setDisplayName("FormPassword"));
}
export var FormPassword = enhance()(Render);
