import { pick } from "ramda";
import { combineReducers, createStore } from "redux";
import { notification } from "./notification/reducers";
import { remember } from "./remember-me/reducers";
import { session } from "./session/reducers";
export function dehydrate(state) {
    localStorage.setItem("state", JSON.stringify({
        remember: pick(["active"], state.remember),
        session: state.session
            ? pick(["id", "access"], state.session)
            : null
    }));
}
export function rehydrate() {
    return JSON.parse(localStorage.getItem("state") || "{}");
}
export var reducers = combineReducers({
    notification: notification,
    remember: remember,
    session: session
});
export var store = createStore(reducers, rehydrate());
store.subscribe(function () {
    dehydrate(store.getState());
});
