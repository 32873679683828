import { Collapse, Fade, Typography, withStyles } from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import * as React from "react";
import { compose, pure, setDisplayName } from "recompose";
import { withNotification } from "enhancers";
import { styles } from "./Notification.styles";
export var Render = function (_a) {
    var classes = _a.classes, _b = _a.notification, data = _b.data, show = _b.show, t = _a.t;
    return React.createElement(Collapse, { in: show }, data &&
        React.createElement(Fade, { in: show },
            React.createElement(Typography, { className: classes.root + " " + classes[data.type.toLowerCase()] }, t(data.message))));
};
export function enhance() {
    return compose(withTranslation(), withStyles(styles), withNotification(), pure, setDisplayName("Notification"));
}
export var Notification = enhance()(Render);
