import { withStyles } from "@material-ui/core";
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { compose, setDisplayName } from "recompose";
import { Authenticate, Leave, NotFound, Register, RegisterVerification, Reset, ResetVerification, AdminRegisterVerification, AuthenticateWithProvider } from "routes";
import { styles } from "./App.styles";
export var Render = function (_a) {
    var classes = _a.classes;
    return React.createElement("div", { className: classes.root + ' auth-container' },
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: "/", component: Authenticate }),
            React.createElement(Route, { exact: true, path: "/leave", component: Leave }),
            window.env.ENABLE_REGISTRATION === "true" && React.createElement(Route, { exact: true, path: "/register", component: Register }),
            window.env.ENABLE_REGISTRATION === "true" && React.createElement(Route, { path: "/register/:code+", component: RegisterVerification }),
            React.createElement(Route, { exact: true, path: "/authorize", component: AuthenticateWithProvider }),
            React.createElement(Route, { exact: true, path: "/reset", component: Reset }),
            React.createElement(Route, { path: "/reset/:code+", component: ResetVerification }),
            window.env.ENABLE_ADMINREGISTRATION === "true" && React.createElement(Route, { path: "/adminregister/:code+", component: AdminRegisterVerification }),
            React.createElement(Route, { component: NotFound })));
};
export function enhance() {
    return compose(withStyles(styles), setDisplayName("App"));
}
export var App = enhance()(Render);
