import * as React from "react";
import { branch, compose, lifecycle, pure, renderComponent, setDisplayName } from "recompose";
import { Loading } from "components";
import { withFormSubmit, withRememberMe } from "enhancers";
import { RegisterVerificationSuccess } from "./RegisterVerificationSuccess";
import i18n from "../../i18n";
export var Render = function () { return React.createElement(Loading, null); };
export function enhance() {
    return compose(withRememberMe(), withFormSubmit({
        message: i18n.t("register_verified") +
            i18n.t("use_email")
    }), lifecycle({
        componentDidMount: function () {
            var _a = this.props, submit = _a.submit, setRememberMeFailed = _a.setRememberMeFailed;
            setRememberMeFailed();
            return submit();
        }
    }), branch(function (_a) {
        var form = _a.form;
        return form.success || Boolean(form.err);
    }, renderComponent(RegisterVerificationSuccess)), pure, setDisplayName("RegisterVerification"));
}
export var RegisterVerification = enhance()(Render);
