import { Button, withStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation } from 'react-i18next';
import { GoogleIcon, SamlIcon } from "components";
import { compose, pure, setDisplayName } from "recompose";
import { styles } from "./IdentityProvider.styles";
export var Render = function (_a) {
    var classes = _a.classes, url = _a.url, type = _a.type, t = _a.t;
    return React.createElement("div", { className: classes.root + ' auth-identityprovider' },
        React.createElement(Button, { href: url, style: { textTransform: 'none', fontWeight: 'normal', padding: '10px 0', width: '100%', fontSize: '1rem' } },
            type === "Google" ? React.createElement(GoogleIcon, null) : React.createElement(SamlIcon, null),
            " ",
            t("continue_with"),
            " ",
            type));
};
export function enhance() {
    return compose(withTranslation(), withStyles(styles), pure, setDisplayName("IdentityProvider"));
}
export var IdentityProvider = enhance()(Render);
