import { connect } from "react-redux";
import { initSessionAction, terminateSessionAction } from "providers/store/session";
export var withSession = function () {
    return connect(function (_a) {
        var session = _a.session;
        return ({ session: session });
    }, function (dispatch) { return ({
        initSession: function (session) { return dispatch(initSessionAction(session)); },
        terminateSession: function () { return dispatch(terminateSessionAction()); }
    }); });
};
