import * as React from "react";
import { Redirect } from "react-router-dom";
import { compose, lifecycle, pure, setDisplayName } from "recompose";
import { withNotification } from "enhancers";
export var Render = function () { return React.createElement(Redirect, { to: "/" }); };
export function enhance() {
    return compose(withNotification(), lifecycle({
        componentWillMount: function () {
            var _a = this.props, form = _a.form, dismissNotification = _a.dismissNotification;
            if (form.err)
                dismissNotification();
        }
    }), pure, setDisplayName("LeaveSuccess"));
}
export var LeaveSuccess = enhance()(Render);
