import { createStyles } from "@material-ui/core";
export var styles = function (_a) {
    var palette = _a.palette;
    return createStyles({
        root: {
            "color": palette.primary.main,
            "outline": 0,
            "textDecoration": "none",
            "transition": "color .125s",
            "&:hover": {
                color: palette.secondary.main
            }
        }
    });
};
