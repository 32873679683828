var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Checkbox } from "@material-ui/core";
import { omit } from "ramda";
import * as React from "react";
import { compose, mapProps, pure, setDisplayName, withHandlers } from "recompose";
import { withRememberMe } from "enhancers";
export var Render = function (_a) {
    var handleChange = _a.handleChange, props = __rest(_a, ["handleChange"]);
    return React.createElement(Checkbox, __assign({}, props, { onChange: handleChange }));
};
export function enhance() {
    return compose(withRememberMe(), withHandlers({
        handleChange: function (_a) {
            var onChange = _a.onChange, setRememberMe = _a.setRememberMe;
            return function (ev, checked) {
                onChange(ev, checked);
                setRememberMe(checked);
            };
        }
    }), mapProps(omit([
        "remember",
        "setRememberMe",
        "setRememberMeFailed"
    ])), pure, setDisplayName("FormRememberMe"));
}
export var FormRememberMe = enhance()(Render);
