var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { lensProp, set } from "ramda";
import { compose, withHandlers, withState } from "recompose";
import { withFormSubmit } from "enhancers";
export var withForm = function (_a) {
    var initial = _a.initial, options = __rest(_a, ["initial"]);
    return compose(withFormSubmit(options), withState("request", "setRequest", initial), withHandlers({
        handleChange: function (_a) {
            var request = _a.request, setRequest = _a.setRequest;
            return function (ev) {
                var _a = ev.currentTarget, type = _a.type, name = _a.name, value = _a.value, checked = _a.checked;
                type === "checkbox"
                    ? setRequest(set(lensProp(name), checked, request))
                    : setRequest(set(lensProp(name), value, request));
            };
        },
        handleSubmit: function (_a) {
            var submit = _a.submit, request = _a.request;
            return function (ev) {
                ev.preventDefault();
                return submit(request);
            };
        }
    }));
};
