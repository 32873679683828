import { connect } from "react-redux";
import { dismissNotificationAction, displayNotificationAction } from "providers/store/notification";
export var withNotification = function () {
    return connect(function (_a) {
        var notification = _a.notification;
        return ({ notification: notification });
    }, function (dispatch) { return ({
        displayNotification: function (data) { return dispatch(displayNotificationAction(data)); },
        dismissNotification: function () { return dispatch(dismissNotificationAction()); }
    }); });
};
