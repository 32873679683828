var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TextField } from "@material-ui/core";
import * as React from "react";
import { compose, pure, setDisplayName } from "recompose";
export var Render = function (props) {
    return React.createElement(TextField, __assign({ type: "text", margin: "dense", InputLabelProps: { required: false }, fullWidth: true, autoCapitalize: "false", autoCorrect: "false" }, props));
};
export function enhance() {
    return compose(pure, setDisplayName("FormInput"));
}
export var FormInput = enhance()(Render);
