import { createStyles } from "@material-ui/core";
export var styles = function (_a) {
    var _b;
    var breakpoints = _a.breakpoints;
    return createStyles({
        root: (_b = {
                width: 340,
                borderRadius: 2,
                overflow: "hidden"
            },
            _b[breakpoints.down("xs")] = {
                width: "100%",
                borderRadius: 0
            },
            _b)
    });
};
