var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormControlLabel, FormGroup, Typography, withStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation } from 'react-i18next';
import { branch, compose, lifecycle, pure, renderComponent, setDisplayName } from "recompose";
import { Dialog, Form, FormButton, FormInput, FormPassword, FormRememberMe, Header, Notification, TextLink, IdentityProvider } from "components";
import { withForm } from "enhancers";
import { AuthenticateSuccess } from "./AuthenticateSuccess";
import { styles } from "./AuthenticateWithCredentials.styles";
export var Render = function (_a) {
    var classes = _a.classes, form = _a.form, request = _a.request, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, t = _a.t;
    return React.createElement(Dialog, null,
        React.createElement(Header, { primary: window.env.COGNITO_IDENTITY_POOL_NAME, secondary: t('signin_account') }),
        React.createElement(Notification, null),
        React.createElement(Form, { onSubmit: handleSubmit },
            React.createElement(FormInput, { name: "username", label: t('email_address'), required: true, disabled: form.success, value: request.username, InputProps: { readOnly: form.pending }, onChange: handleChange, autoComplete: "username" }),
            React.createElement(FormPassword, { name: "password", label: t('password'), required: true, disabled: form.success, value: request.password, InputProps: { readOnly: form.pending }, onChange: handleChange, autoComplete: "password" }),
            window.env.ENABLE_GOOGLE === "true" && React.createElement(IdentityProvider, { type: "Google", url: "https://" + window.env.OAUTH2_PREFIX + ".auth." + window.env.REGION + ".amazoncognito.com/oauth2/authorize?response_type=code&client_id=" + window.env.COGNITO_IDENTITY_POOL_CLIENT_ID + "&redirect_uri=https://" + window.env.COGNITO_IDENTITY_POOL_PROVIDER + "/authorize&state=state&identity_provider=Google&scope=email openid profile" }),
            window.env.ENABLE_SAML === "true" && React.createElement(IdentityProvider, { type: window.env.SAML_PROVIDER, url: "https://" + window.env.OAUTH2_PREFIX + ".auth." + window.env.REGION + ".amazoncognito.com/oauth2/authorize?response_type=code&client_id=" + window.env.COGNITO_IDENTITY_POOL_CLIENT_ID + "&redirect_uri=https://" + window.env.COGNITO_IDENTITY_POOL_PROVIDER + "/authorize&state=state&identity_provider=" + window.env.SAML_PROVIDER + "&scope=email openid profile" }),
            React.createElement(FormGroup, { row: true, className: classes.controls },
                React.createElement(FormControlLabel, { label: t('remember'), control: React.createElement(FormRememberMe, { name: "remember", checked: request.remember, onChange: handleChange }) }),
                React.createElement(Typography, { className: classes.forgotPassword },
                    React.createElement(TextLink, { to: "/reset", tabIndex: -1 }, t('forgot')))),
            React.createElement(FormButton, { disabled: form.pending }, t('sign_in')),
            window.env.ENABLE_REGISTRATION === "true" &&
                React.createElement(Typography, { className: classes.register },
                    t("no_account"),
                    " ",
                    React.createElement(TextLink, { to: "/register" }, t("register")))));
};
export function enhance() {
    return compose(withStyles(styles), withTranslation(), withForm({
        target: "/authenticate",
        initial: {
            username: "",
            password: "",
            remember: false
        }
    }), lifecycle({
        componentWillMount: function () {
            var _a = this.props, remember = _a.remember, setRememberMeFailed = _a.setRememberMeFailed;
            var _b = this.props, request = _b.request, setRequest = _b.setRequest;
            setRememberMeFailed();
            setRequest(__assign(__assign({}, request), { remember: remember.active }));
        }
    }), branch(function (_a) {
        var form = _a.form;
        return form.success && Boolean(form.response);
    }, renderComponent(AuthenticateSuccess)), pure, setDisplayName("AuthenticateWithCredentials"));
}
export var AuthenticateWithCredentials = enhance()(Render);
