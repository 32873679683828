export var RememberMeActionTypes;
(function (RememberMeActionTypes) {
    RememberMeActionTypes["SET"] = "REMEMBER_ME_SET";
    RememberMeActionTypes["SET_FAILED"] = "REMEMBER_ME_SET_FAILED";
})(RememberMeActionTypes || (RememberMeActionTypes = {}));
export var setRememberMeAction = function (active) { return ({
    type: RememberMeActionTypes.SET,
    active: active
}); };
export var setRememberMeFailedAction = function () { return ({
    type: RememberMeActionTypes.SET_FAILED
}); };
