import { Paper, withStyles } from "@material-ui/core";
import * as React from "react";
import { compose, pure, setDisplayName } from "recompose";
import { styles } from "./Dialog.styles";
export var Render = function (_a) {
    var classes = _a.classes, children = _a.children;
    return React.createElement(Paper, { elevation: 1, className: classes.root }, children);
};
export function enhance() {
    return compose(withStyles(styles), pure, setDisplayName("Dialog"));
}
export var Dialog = enhance()(Render);
