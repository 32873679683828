var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MuiThemeProvider } from "@material-ui/core";
import * as React from "react";
import { render } from "react-dom";
import { Provider as StateProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import './i18n';
import { App } from "components/App";
import { store, theme } from "providers";
var config = document.querySelectorAll("meta[name^=config]");
window.env = Array.from(config).reduce(function (env, meta) {
    var _a;
    var key = meta.name.replace(/^config\:/, "");
    return __assign(__assign({}, env), (_a = {}, _a[key.toUpperCase()] = meta.content, _a));
}, {});
render(React.createElement(StateProvider, { store: store },
    React.createElement(MuiThemeProvider, { theme: theme },
        React.createElement(BrowserRouter, null,
            React.createElement(App, null)))), document.getElementById("root"));
