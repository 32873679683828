export var SessionActionTypes;
(function (SessionActionTypes) {
    SessionActionTypes["INIT"] = "SESSION_INIT";
    SessionActionTypes["TERMINATE"] = "SESSION_TERMINATE";
})(SessionActionTypes || (SessionActionTypes = {}));
export var initSessionAction = function (session) { return ({
    type: SessionActionTypes.INIT,
    session: session
}); };
export var terminateSessionAction = function () { return ({
    type: SessionActionTypes.TERMINATE
}); };
