import { colors, createMuiTheme } from "@material-ui/core";
export var theme = createMuiTheme({
    palette: {
        primary: colors.indigo,
        secondary: colors.lightBlue,
        error: {
            main: colors.red.A200
        }
    }
});
