export var NotificationActionTypes;
(function (NotificationActionTypes) {
    NotificationActionTypes["DISPLAY"] = "NOTIFICATION_DISPLAY";
    NotificationActionTypes["DISMISS"] = "NOTIFICATION_DISMISS";
})(NotificationActionTypes || (NotificationActionTypes = {}));
export var displayNotificationAction = function (data) { return ({
    type: NotificationActionTypes.DISPLAY,
    data: data
}); };
export var dismissNotificationAction = function () { return ({
    type: NotificationActionTypes.DISMISS
}); };
