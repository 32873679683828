import * as React from "react";
import { branch, compose, lifecycle, pure, renderComponent, setDisplayName } from "recompose";
import { Loading } from "components";
import { withFormSubmit, withAuthorization, withRememberMe } from "enhancers";
import { AuthenticateWithProviderSuccess } from "./AuthenticateWithProviderSuccess";
import i18n from "../../i18n";
export var Render = function () { return React.createElement(Loading, null); };
export function enhance() {
    return compose(withRememberMe(), withFormSubmit({
        message: i18n.t("register_verified") +
            i18n.t("use_email")
    }), withAuthorization(), lifecycle({
        componentDidMount: function () {
            var _a = this.props, submit = _a.submit, code = _a.code, state = _a.state, setRememberMe = _a.setRememberMe;
            setRememberMe(true);
            return submit({ code: code, state: state });
        }
    }), branch(function (_a) {
        var form = _a.form;
        return form.success || Boolean(form.err);
    }, renderComponent(AuthenticateWithProviderSuccess)), pure, setDisplayName("AuthenticateWithProvider"));
}
export var AuthenticateWithProvider = enhance()(Render);
