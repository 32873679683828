import { Typography, withStyles } from "@material-ui/core";
import * as React from "react";
import { withTranslation } from 'react-i18next';
import i18n from "../../i18n";
import { compose, pure, setDisplayName } from "recompose";
import { Dialog, Form, FormButton, FormInput, FormPassword, Header, Notification, TextLink } from "components";
import { withForm } from "enhancers";
import { styles } from "./Register.styles";
export var Render = function (_a) {
    var classes = _a.classes, form = _a.form, request = _a.request, handleChange = _a.handleChange, handleSubmit = _a.handleSubmit, t = _a.t;
    return React.createElement(Dialog, null,
        React.createElement(Header, { primary: window.env.COGNITO_IDENTITY_POOL_NAME, secondary: t("register_account") }),
        React.createElement(Notification, null),
        React.createElement(Form, { onSubmit: handleSubmit },
            React.createElement(FormInput, { name: "email", label: t("email_address"), required: true, disabled: form.success, value: request.email, InputProps: { readOnly: form.pending }, onChange: handleChange, autoComplete: "email" }),
            React.createElement(FormPassword, { name: "password", label: t("password"), required: true, disabled: form.success, value: request.password, InputProps: { readOnly: form.pending }, onChange: handleChange, autoComplete: "new-password" }),
            React.createElement(FormButton, { className: classes.button, disabled: form.pending || form.success }, t("register")),
            React.createElement(Typography, { className: classes.authenticate },
                t("have_account"),
                " ",
                React.createElement(TextLink, { to: "/" }, t("sign_in")))));
};
export function enhance() {
    return compose(withTranslation(), withStyles(styles), withForm({
        message: i18n.t("sent_link") +
            i18n.t("register_click"),
        initial: {
            email: "",
            password: ""
        }
    }), pure, setDisplayName("Register"));
}
export var Register = enhance()(Render);
